'use client'

import React, { useState } from 'react'
import { signIn } from 'next-auth/react'

import { Logo } from '@fsg/svg'

import { Button, LightProgressLoader, LoadingSpinner } from '../'

export function Login() {
  const [loading, setLoading] = useState(false)

  const onSignIn = () => {
    setLoading(true)
    signIn('fsg')
  }

  return (
    <div className="col-span-full row-span-full grid h-[100vh] items-center">
      <div className="flex flex-col content-start items-center">
        <Logo className={loading ? 'transition-duration-[300ms] opacity-0 transition-opacity' : ''} />
        {loading ? (
          // REVIEW >> This container div is a hack to keep it the same size as the Button
          // REVIEW >> ...once Button is updated to use DS, this will clean up
          <div className="m-px h-14">
            <LightProgressLoader />
          </div>
        ) : (
          <Button buttonType="primary" size="medium" onClick={onSignIn} loading={loading}>
            Log in
          </Button>
        )}
        <div
          className={`mx-auto mt-24 w-3/5 max-w-[420px] rounded-md bg-white p-6 text-justify text-md text-gray-darker ${
            loading ? 'transition-duration-[300ms] opacity-0 transition-opacity' : ''
          }`}
        >
          <p>
            <strong className="text-gray-darkest">THIS IS A PRIVATE COMPUTER SYSTEM.</strong> This computer system, including all related equipment,
            and network devices (specifically including Internet access), is provided only for authorized use.{' '}
            <strong className="text-gray-darkest">Users (authorized or unauthorized) have no explicit or implicit expectation of privacy.</strong>
          </p>
          <p className="mt-2">
            Any or all uses of this system and all files on this system may be intercepted, monitored, recorded, copied, audited, inspected, and
            disclosed to authorized site and law enforcement personnel, as well as authorized officials of other agencies, both domestic and foreign.{' '}
            <strong className="text-gray-darkest">
              By using this system, the user consents to such interception, monitoring, recording, copying, auditing, inspection, and disclosure at
              the discretion of authorized FSG site personnel.
            </strong>
          </p>
          <p className="mt-2">
            Unauthorized or improper use of this system may result in administrative disciplinary action and civil and criminal penalties. By
            continuing to use this system, you indicate your awareness of and consent to these terms and conditions of use.{' '}
            <strong className="text-gray-darkest">LOG OFF IMMEDIATELY if you do not agree to the conditions stated in this warning.</strong>
          </p>
        </div>
      </div>
    </div>
  )
}
