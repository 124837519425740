import React from 'react'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

import { AGGridCore } from '@fsg/gui-bits'

import { AGGridWrapperProps } from '../../types' // REVIEW >> For some reason the alias caused Vercel builds to fail

ModuleRegistry.registerModules([ServerSideRowModelModule])

export function AGGridServerSide(props: AGGridWrapperProps) {
  return <AGGridCore {...props} />
}
