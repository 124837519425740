import Link, { LinkProps } from 'next/link'
import cn from 'classnames'
import moduleStyles from './NavItem.module.scss'

export type NavItemProps = LinkProps & {
  value?: string
  children?: string
  className?: string
}

export function NavItem(props: NavItemProps) {
  const { className, children, value, ...rest } = props

  const navItemClassName = cn(moduleStyles['m--nav-item'], 'text-black text-xl font-semibold', className)

  return (
    <Link prefetch={false} className={navItemClassName} {...rest}>
      <span className={moduleStyles['m--label']}>{children}</span>
    </Link>
  )
}
