import { createContext, MutableRefObject, useContext, useEffect, useRef } from 'react'

import { X } from '@fsg/icons'

type ModalContextType = {
  handleCloseModal: () => void
  modalRef: MutableRefObject<HTMLDialogElement | null>
  fullScreen: boolean
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)
const { Provider } = ModalContext

const styles = {
  modal: {
    dialog: 'w-[400px] rounded-[24px]',
    header: 'mb-[6px] text-center text-[20px] font-semibold',
    content: 'flex flex-col px-[24px] pb-[24px]',
    description: 'text-center text-[16px]',
    actions: 'mt-[24px] flex justify-center gap-lg',
  },
  fullscreenModal: {
    dialog: 'w-[800px] h-[600px] rounded-[24px]',
    header: 'text-[16px] font-semibold',
    content: 'h-full',
    description: '',
    actions: 'mt-[24px] flex justify-end p-xl gap-lg',
  },
}

export function useModalContext() {
  const context = useContext(ModalContext)

  if (!context) throw new Error('useModalContext must be called within a Modal context provider')
  return context
}

type ModalProps = React.PropsWithChildren<{ open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>>; fullScreen?: boolean }>

export function Modal({ open, setOpen, children, fullScreen = false }: ModalProps) {
  const modalRef = useRef<HTMLDialogElement | null>(null)

  useEffect(() => {
    const modalElement = modalRef.current

    if (!modalElement) return

    if (open) {
      modalElement.showModal()
      modalElement.focus()
    } else {
      modalElement.close()
    }
  }, [open])

  function handleCloseModal() {
    setOpen(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      handleCloseModal()
    }
  }

  const contextValue: ModalContextType = {
    handleCloseModal,
    modalRef,
    fullScreen: fullScreen,
  }

  return (
    <Provider value={contextValue}>
      <dialog ref={modalRef} onKeyDown={handleKeyDown} className={!fullScreen ? styles.modal.dialog : styles.fullscreenModal.dialog}>
        <div className="fixed right-0 top-0 z-[99999]" id="modal-notification-portal"></div>
        {!fullScreen && (
          <div className="p-[8px]">
            <X className="m-[6px] ml-auto" role="button" onClick={handleCloseModal} tabIndex={0} />
          </div>
        )}
        <div className={!fullScreen ? styles.modal.content : styles.fullscreenModal.content}>{children}</div>
      </dialog>
    </Provider>
  )
}

interface ModalComponents extends React.PropsWithChildren {
  className?: string
}

function Heading({ children, className }: ModalComponents) {
  const { fullScreen, handleCloseModal } = useModalContext()
  return !fullScreen ? (
    <h2 className={styles.modal.header}>{children}</h2>
  ) : (
    <div className={`flex items-center border-b border-gray-300 p-lg ${styles.fullscreenModal.header} ${className}`}>
      <h2> {children}</h2>
      <X className="m-[6px] ml-auto" role="button" onClick={handleCloseModal} tabIndex={0} />
    </div>
  )
}

function Description({ children, className }: ModalComponents) {
  const { fullScreen } = useModalContext()
  return <p className={!fullScreen ? styles.modal.description : styles.fullscreenModal.description}>{children}</p>
}

function Content({ children, className }: ModalComponents) {
  const { fullScreen } = useModalContext()
  return <div className={!fullScreen ? styles.modal.description : styles.fullscreenModal.description}>{children}</div>
}

type ActionsProps = {
  children: React.ReactNode | ((props: { handleCloseModal: () => void }) => React.ReactNode)
  className?: string
}

function Actions({ children, className }: ActionsProps) {
  const { handleCloseModal, fullScreen } = useModalContext()
  let content

  if (typeof children === 'object') {
    content = children
  } else if (typeof children === 'function') {
    content = children({ handleCloseModal })
  }
  return <div className={!fullScreen ? styles.modal.actions : styles.fullscreenModal.actions}>{content}</div>
}

Modal.Heading = Heading
Modal.Description = Description
Modal.Actions = Actions
Modal.Content = Content
