import cn from 'classnames/bind'

import { parseNumberFromString } from '../../../fsg-utils/src'
import moduleStyles from './FinancialText.module.scss'

const cx = cn.bind(moduleStyles)

export type FinancialTextProps = {
  currency?: 'USD'
  className?: string
  symbolClassName?: string
  children: string
  decimals?: number
  style?: any
}

const currencySymbols = {
  USD: '$',
  // ...add more currencies?
}

export function FinancialText(props: FinancialTextProps) {
  const { decimals = 2, currency = 'USD', className, symbolClassName, children, style } = props

  if (!(typeof children == 'string')) throw new Error(`FinancialText requires a single string child: ${children}`)

  const number = parseNumberFromString(children)
  const isNotANumber = isNaN(number)
  const isNegativeNumber = !isNotANumber && Math.abs(number) !== number
  const displayNumber = isNotANumber
    ? ['null', 'undefined', ''].includes(children)
      ? '-'
      : children
    : formatNumber(isNegativeNumber ? -number : number, decimals)

  const formattedDisplayNumber = isNegativeNumber ? `(${displayNumber})` : displayNumber

  return (
    <div
      style={style}
      className={cx('m--financial-text', className, {
        ['m--negative-number']: isNegativeNumber,
      })}
    >
      {!isNotANumber ? <span className={cx('m--symbol', symbolClassName)}>{currencySymbols[currency]}</span> : <span></span>}
      <span className={moduleStyles.value}>{formattedDisplayNumber}</span>
    </div>
  )
}

function formatNumber(number: number, decimals: number) {
  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    minimumIntegerDigits: 1,
  })

  return formatter.format(number)
}
