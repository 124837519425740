import { useCallback, useEffect, useState } from 'react'
import { signOut, useSession } from 'next-auth/react'
import { Status } from './types'

interface FetchOptions extends RequestInit {
  url?: string
  path?: string
  auth?: boolean
  refresh?: boolean
}

// client side version of request helper
export function useRequestOnMount<APIResponseType>(requestOptions: FetchOptions, dataDefaultState?: APIResponseType) {
  const { request } = useRequestCallback()

  const [data, setData] = useState<APIResponseType>(dataDefaultState)
  const [status, setStatus] = useState<Status>('idle')
  const [error, setError] = useState(null)

  useEffect(() => {
    let mounted = true
    const controller = new AbortController()

    const getData = async () => {
      setStatus('loading')
      try {
        const res = await request<APIResponseType>({ ...requestOptions, signal: controller.signal })
        if (!mounted) return
        setData(res)
        setStatus('success')
      } catch (err: any) {
        if (!mounted) return
        setStatus('error')
        setError(error)
      }
    }

    getData()

    return () => {
      mounted = false
      controller.abort()
    }
  }, [])

  return { request, data, status, error }
}

function isAuthExpired(expires: string) {
  return new Date().getTime() < new Date(expires).getTime()
}

export function useRequestCallback() {
  const { data: session, status: sessionStatus } = useSession()

  const request = useCallback(async <APIResponseType>({ url, path, auth = true, refresh = false, ...options }: FetchOptions = {}) => {
    if (url && path) {
      throw new Error('URL and Path are mutually exclusive')
    }

    if (!url && !path) {
      throw new Error('Either URL or Path is required')
    }

    let requestUrl = path ? process.env.NEXT_PUBLIC_API_GATEWAY_BASE_URL + path : url

    if (refresh) requestUrl += requestUrl.includes('?') ? '&refresh=1' : '?refresh=1'

    if (auth) {
      if (sessionStatus === 'unauthenticated') {
        signOut()
        throw new Error(`You must be signed in to access this resource (${requestUrl})`)
      }

      if (!isAuthExpired(session?.expires)) {
        signOut()
        throw new Error(`The session is expired: ${session}`)
      }
    }
    const response = await fetch(requestUrl, {
      ...options,
      mode: 'cors',
      headers: {
        ...(options.headers || {}),
        ...(auth && session?.user ? { Authorization: `Bearer ${session.accessToken}` } : {}),
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      const errorResponse = await response.json()
      if (errorResponse?.responseData) {
        throw new Error(errorResponse.responseData)
      } else if (errorResponse?.message) {
        throw new Error(`Failed to fetch data (${response.status}): ${errorResponse.message}`)
      } else {
        throw new Error(`Failed to fetch data (${response.status})`)
      }
    }

    const data: APIResponseType = await response.json()
    return data
  }, [])

  return { request, session, sessionStatus }
}
