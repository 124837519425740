import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'

import { cn } from '@fsg/utils'

import type { VariantProps } from 'class-variance-authority'

import { ButtonV2LoadingSpinner } from './ButtonV2LoadingSpinner'

// TODO >> Use useFormStatus() useFormState()
// TODO >> Use aria-disabled
// TODO >> Fix button click active/focus issue
// TODO >> Animate ButtonV2LoadingSpinner mounting/unmounting
// TODO >> type ButtonVariants = RequiredVariantProps<typeof buttonVariants>["..."] https://cva.style/docs/getting-started/typescript#required-variants
type ButtonVariants = Omit<VariantProps<typeof buttonVariants>, '_icon'>
export interface ButtonV2Props extends React.ButtonHTMLAttributes<HTMLButtonElement>, ButtonVariants {
  asChild?: boolean
  iconOnly?: boolean
  loading?: boolean | null
}

// TODO >> rounded="full" is janky on circular buttons (might have something to do with the leading)
const variants = ['primary', 'secondary', 'ghost', 'success', 'warning', 'error'] satisfies ButtonVariants['variant'][]
const sizes = ['sm', 'md', 'lg'] satisfies ButtonVariants['size'][]
const rounded = ['none', 'sm', 'md', 'lg', 'full'] satisfies ButtonVariants['rounded'][]
export { variants as ButtonVariants, sizes as ButtonSizes, rounded as ButtonRounded }

// ? `h-auto min-h-0 self-start` prevent button from growing to fill parent container height in some situations
const buttonVariants = cva(
  'inline-flex relative h-auto min-h-0 self-start [&>*]:leading-none items-center font-medium transition-all duration-200 ease-in-out rounded-md outline-none border py-1 px-2 disabled:cursor-progress disabled:text-white disabled:hover:bg-gray-500 active:ring-2 active:ring-blue-500',
  {
    variants: {
      variant: {
        primary: 'border-color-primary bg-blue-500 hover:bg-blue-600 focus:bg-blue-600 text-white', // * default
        secondary: 'border-gray-light bg-white hover:bg-gray-lightest focus:bg-gray-lightest text-gray-darkest',
        ghost: 'border-none text-gray-darkest hover:bg-gray-lightest focus:bg-gray-lightest',
        success: 'bg-color-success hover:bg-success-darker focus:bg-success-darker text-white',
        warning: 'bg-color-warning hover:bg-warning-darker focus:bg-warning-darker',
        error: 'bg-color-error hover:bg-error-darker focus:bg-error-darker text-white',
        // TODO >> link: 'text-primary underline-offset-4 hover:underline',
      },
      rounded: {
        none: 'rounded-none',
        sm: 'rounded-sm',
        md: 'rounded-md', // * sefault
        lg: 'rounded-lg',
        full: 'rounded-full',
      },
      size: {
        sm: 'pt-2 pb-[0.65rem] px-3 text-md gap-sm',
        md: 'pt-[0.65rem] pb-3 px-5 text-xl gap-md', // * default
        lg: 'pt-3 pb-[1rem] px-7 text-2xl gap-lg',
      },
      _icon: {
        // * Kind of a hack in order to use compoundVariants
        yes: '',
        no: '',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
      rounded: 'md',
      _icon: 'no',
    },
    compoundVariants: [
      {
        variant: ['primary', 'secondary', 'ghost', 'success', 'warning', 'error'],
        _icon: 'yes',
        size: 'sm',
        class: 'p-2',
      },
      {
        variant: ['primary', 'secondary', 'ghost', 'success', 'warning', 'error'],
        _icon: 'yes',
        size: 'md',
        class: 'p-3',
      },
      {
        variant: ['primary', 'secondary', 'ghost', 'success', 'warning', 'error'],
        _icon: 'yes',
        size: 'lg',
        class: 'p-4',
      },
    ],
  },
)

const ButtonV2 = React.forwardRef<HTMLButtonElement, ButtonV2Props>(
  ({ className, variant, size, rounded, loading, asChild = false, iconOnly = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    const icon = iconOnly ? 'yes' : 'no'
    function LoadingIndicator() {
      return (
        <>
          <div
            className={cn(
              buttonVariants({ variant, size, rounded, _icon: icon }),
              'pointer-events-none absolute inset-0 z-10 h-full w-full cursor-progress opacity-70',
              className,
            )}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          ></div>
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <ButtonV2LoadingSpinner className="h-[1em] w-[1em] animate-spin" />
          </div>
        </>
      )
    }

    return asChild ? (
      // Note: next/link doesn't currently support loading spinner
      <Comp
        className={cn(buttonVariants({ variant, size, rounded, _icon: icon, className }))}
        disabled={loading ?? false}
        ref={ref}
        {...props}
      ></Comp>
    ) : (
      <Comp className={cn(buttonVariants({ variant, size, rounded, _icon: icon, className }))} disabled={loading ?? false} ref={ref} {...props}>
        {loading ? <LoadingIndicator></LoadingIndicator> : null}
        {/* // * Children are outside the ternary in order to maintain button dimensions */}
        {props.children}
      </Comp>
    )
  },
)
ButtonV2.displayName = 'Button'

export { ButtonV2, buttonVariants }
