'use client'

import React from 'react'
import { LicenseManager } from '@ag-grid-enterprise/core'

// const setGridLicense = () => {
//   const licenseKey =
//     'Using_this_AG_Grid_Enterprise_key_( AG-042964 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Facility Solutions Group, Inc )_is_granted_a_( Multiple Applications )_Developer_License_for_( 3 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 24 June 2024 )____[v2]_MTcxOTE4MzYwMDAwMA==add60d1200d6e860c439296c3d979842'
//   if (licenseKey) {
//     LicenseManager.setLicenseKey(licenseKey)
//   }
// }

type Props = {
  children?: React.ReactNode
}

export function AGGridContext(props: Props) {
  // REVIEW >> useEffect was preventing license being set!  Also, it was unnecessarily complex. #code-review--agg-v30
  // React.useEffect(() => {
  //   setGridLicense()
  // })
  // const { children } = props

  const licenseKey =
    'Using_this_AG_Grid_Enterprise_key_( AG-042964 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Facility Solutions Group, Inc )_is_granted_a_( Multiple Applications )_Developer_License_for_( 3 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 24 June 2024 )____[v2]_MTcxOTE4MzYwMDAwMA==add60d1200d6e860c439296c3d979842'
  LicenseManager.setLicenseKey(licenseKey)

  return <>{props.children}</>
}
