import React, { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  color?: string
}

export function Label(props: Props) {
  const { color = 'bg-color-primary-dark', children } = props

  const labelClass = `text-white text-xl font-bold px-md rounded-lg ${color}`

  return <div className={labelClass}>{children}</div>
}
