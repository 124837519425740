'use client'

import React from 'react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import {
  ColDef,
  Column,
  ColumnResizedEvent,
  FilterChangedEvent,
  FilterModifiedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  ModuleRegistry,
  RowDataUpdatedEvent,
} from '@ag-grid-community/core'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { AgGridReact } from '@ag-grid-community/react'
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { SideBarModule } from '@ag-grid-enterprise/side-bar'
import debounce from 'just-debounce-it'
import useDimensions from 'react-cool-dimensions'

import { AGGridContext, Button, ButtonV2 } from '@fsg/gui-bits'
import { cn } from '@fsg/utils'

import { AGGridWrapperProps } from '../../types' // REVIEW >> For some reason the alias caused Vercel builds to fail

ModuleRegistry.registerModules([
  AdvancedFilterModule,
  ClientSideRowModelModule,
  MultiFilterModule,
  SetFilterModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  MenuModule,
  SideBarModule,
])

// import { useScrollbarWidth } from '../../../fsg-utils/src/hooks' // REVIEW >> not sure why @fsg/utils isn't working here
// import { gridInitialState, gridReducer } from './gridReducer'

// TODO >> Research https://www.ag-grid.com/react-data-grid/column-sizing/#enable-sizing
// TODO >> loading skeleton
// TODO >> animate transition from loading skeleton to populated grid
// TODO >> cellSizeRadios feature (commented out currently)

interface WindowSize {
  width: number | undefined
  height: number | undefined
}

function getTotalColumnsWidth(api: GridApi): number {
  const allColumns: Column[] | null = api.getColumns()
  if (!allColumns) return 0

  let totalWidth: number = 0
  for (let column of allColumns) {
    if (column.isVisible()) totalWidth += column.getActualWidth()
  }
  // console.debug(`\t>> getTotalColumnsWidth: ${totalWidth}`)
  return totalWidth
}

export const GridDefaults = {
  paginationPageSize: 20,
}

export function AGGridCore({
  className,
  responsiveOptions,
  gridRef,
  header: customHeader,
  inlineAddRow,
  // REVIEW >> Seems like a TS subversion to spread GridOptions here...
  ...props
}: AGGridWrapperProps) {
  const isClient = typeof window === 'object'
  // * Default to responsive width (justify), but not height (align)
  const { justify = 'auto', justifyOptions, align, alignOptions } = responsiveOptions || {}
  // Note >> A little confusing to have width in an align property (align being vertical alignment), but `alignStretchMinWidth` indicates at which container minimum width (normally in coordination with a breakpoint) we should allow something other than `autoHeight`.  On mobile/tablet we always want `domLayout="autoHeight"`.
  const { alignStretchMinWidth = 10, minGridHeight = 190 } = alignOptions || {}
  // const minHeightClass = `min-h-[${minGridHeight}px]`
  // * need to check if align option is set
  const autoAlign = align === 'auto'
  const [alignStretch, setAlignStretch] = React.useState(false)
  // * if so, we need to check if total rows height + header + footer is less than container height (in which case, we need to set domLayout to 'autoHeight')
  // const stretchClassVariant = `[@media(min-width:${alignStretchMinWidth}px)]:grid-rows-[auto_1fr_auto]`
  // const stretchClassVariant = '[@media(min-width:900px)]:grid-rows-[auto_1fr_auto]'
  // const stretchClassVariant = `min-[${alignStretchMinWidth}px]:grid-rows-[auto_1fr_auto]` // Doesn't work
  // const stretchClassVariant = 'lg:grid-rows-[auto_1fr_auto]'
  // const scrollbarWidth = useScrollbarWidth()
  // const [gridReady, setGridReady] = React.useState(false) // <-- maintain a state for grid readiness; gridRef.current.api is null until the grid is ready so any custom components that we create to interact with the grid, such as Pagination, will need to wait until the grid is ready before they can access the grid's api
  // const [state, dispatch] = React.useReducer(gridReducer, gridInitialState)
  const [gridApi, setApi] = React.useState<GridApi | null>(null)
  const [gridWidth, setGridWidth] = React.useState(0) // REVIEW >> strange that we're not using this
  const [minGridWidth, setMinGridWidth] = React.useState(0)
  const [suppressPaginationPanel, setSuppressPaginationPanel] = React.useState(false)
  // const [rowCount, setRowCount] = React.useState(0)
  // const [pageSize, setPageSize] = React.useState(0)

  const getSize = React.useCallback((): WindowSize => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }, [isClient])
  const [windowSize, setWindowSize] = React.useState<WindowSize>(getSize)

  const onExportButton = React.useCallback(() => {
    gridRef.current!.api.exportDataAsCsv()
  }, [gridRef])

  // * Container observer...
  const {
    observe,
    // unobserve, // Note:  You don't have to call unobserve when the component is unmounted, it will be handled automatically.
    width: containerWidth,
    height: containerHeight,
    // entry,
    // currentBreakpoint,
  } = useDimensions({
    // Note: we can apply different styles to a component according to the `currentBreakpoint` state --> https://github.com/wellyshen/react-cool-dimensions#responsive-components
    useBorderBoxSize: true, // Tell the hook to measure based on the border-box size, default is false
    // polyfill: ResizeObserver, // Use polyfill to make this feature works on more browsers - would need to `import { ResizeObserver } from "@juggle/resize-observer"` in order for box-sizing to work properly for useDimensions

    // onResize: ({ observe, unobserve, width: containerWidth, height: containerHeight, entry }) => {
    //   // * Triggered whenever the size of the target is changed...
    //   // if (gridApi) setGridWidth(getTotalColumnsWidth(gridApi))
    //   observe() // * To re-start observing the current target element
    // },
  })

  // * Auto align observer...
  React.useEffect(() => {
    if (!isClient || !autoAlign) return
    // TODO >> If total row height is less than container height `setAlignStretch(false)`
    // let totalHeight = 0
    // const rowHeight = // row height setting in pixels
    // const displayedRowCount = gridApi.getDisplayedRowCount()
    // for (let i = 0; i < displayedRowCount; i++) {
    //   const rowNode = gridApi.getDisplayedRowAtIndex(i)
    //   totalHeight += rowNode.rowHeight ? rowNode.rowHeight : rowHeight
    // }

    // * If window width is greater than alignStretchMinWidth (900px - lg breakpoint), stretch to fit container
    setAlignStretch(Boolean(windowSize.width && windowSize.width >= alignStretchMinWidth))
    // console.debug(`\n--->\nalignStretch: ${Boolean(windowSize.width && windowSize.width >= alignStretchMinWidth)}\n---\n`)
  }, [autoAlign, alignStretchMinWidth, isClient, windowSize.width])

  // * Justify width observer...
  React.useEffect(() => {
    if (!isClient || justify !== 'auto') return
    function onWindowResize() {
      // console.debug(`onWindowResize() --> minGridWidth: ${minGridWidth} -- containerWidth: ${containerWidth}`)
      setWindowSize(getSize())

      if (!gridApi || !gridApi) return
      // * if containerWidth is greater than minGridWidth, sizeColumnsToFit
      if (containerWidth > minGridWidth) gridApi.sizeColumnsToFit(justifyOptions?.autoSizeColumnsOptions)
      else gridApi.autoSizeAllColumns()
    }

    window.addEventListener('resize', onWindowResize)

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [justify, justifyOptions, containerWidth, getSize, gridApi, isClient, minGridWidth])

  // // * Handle rowCount change...
  // React.useEffect(() => {
  //   // ! For some reason this doesn't get called when filters are removed
  //   setSuppressPaginationPanel(rowCount <= pageSize)
  //   console.debug(`\t>> useEffect() --> update row count - setSuppressPaginationPanel( ${rowCount <= pageSize} )\n`)
  // }, [pageSize, rowCount])

  // Note: Using this pattern, `event.api.getDisplayedRowCount()` is always stale by one cycle.  For now going to leave pagination controls visible always.
  const updateRowCount = React.useCallback((event: RowDataUpdatedEvent | FilterChangedEvent | FilterModifiedEvent) => {
    // setPageSize(event.api.paginationGetPageSize())
    // setRowCount(event.api.getDisplayedRowCount())
    // const pageSize = event.api.paginationGetPageSize()
    // const rowCount = event.api.getDisplayedRowCount()
    const pageSize = event.api.paginationGetPageSize()
    const rowCount = event.api.getDisplayedRowCount()
    console.debug(`\t>> updateRowCount() --> rowCount: ${rowCount} pageSize: ${pageSize}`)
    console.debug(event)
    console.debug('')
    // setSuppressPaginationPanel(rowCount <= pageSize)
  }, [])

  const onGridReady = React.useCallback(
    (event: GridReadyEvent) => {
      // event.api.showLoadingOverlay()
      // event.api.showNoRowsOverlay()
      // * Set React.useState values...
      // setGridReady(true)
      setApi(event.api)
      // * Prop drill potential instance callback...
      props.onGridReady && props.onGridReady(event)
    },
    [props],
  )

  // FirstDataRenderedEvent<TData>
  const onFirstDataRendered = React.useCallback(
    (event: FirstDataRenderedEvent) => {
      // console.debug('\nonFirstDataRendered -->')
      // * Prop drill potential instance callback...
      props.onFirstDataRendered && props.onFirstDataRendered(event)

      if (justify !== 'auto') {
        // * If no responsiveOptions.justify, just autoSizeAllColumns
        event.api.autoSizeAllColumns()
        return
      }

      // // * Determine whether to suppressPaginationPanel...
      // // updateRowCount(event)
      // const pageSize = event.api.paginationGetPageSize()
      // const rowCount = event.api.getDisplayedRowCount()
      // console.debug(`\t>> updateRowCount() --> rowCount: ${rowCount} pageSize: ${pageSize}`)

      // * Responsive...
      let columnsWidth: number = 0 // Note: This feels a bit dirty, but minGridWidth isn't yet available and so we need this value to be available for total column width is less than grid container width comparison below
      if (!minGridWidth) {
        // console.debug('\n>> onFirstDataRendered:')
        // * Only run if minGridWidth hasn't been set yet...
        event.api.autoSizeAllColumns()
        columnsWidth = getTotalColumnsWidth(event.api)
        // setGridWidth(columnsWidth) // * don't need to set here, because onColumnResized will set it
        setMinGridWidth(columnsWidth)
        // console.debug(`\tminGridWidth: ${columnsWidth}`)
      }

      // * If total column width is less than grid container width, resize columns to fit...
      // Note: `gridWidth` doesn't account for scrollbar width so there is that potential disconnect between `gridWidth` and `containerWidth` (we may want to mitigate this in the future)
      // console.debug(`\tminGridWidth: ${columnsWidth} -- containerWidth: ${containerWidth}`)
      if (columnsWidth && containerWidth && columnsWidth < containerWidth) {
        justifyOptions?.autoSizeColumnsOptions ? event.api.sizeColumnsToFit(justifyOptions.autoSizeColumnsOptions) : event.api.sizeColumnsToFit()
      }
    },
    [props, justify, minGridWidth, containerWidth, justifyOptions?.autoSizeColumnsOptions],
  )

  // // RowDataUpdatedEvent<TData>
  // const onRowDataUpdated = React.useCallback(
  //   (event: RowDataUpdatedEvent) => {
  //     console.debug('\nonRowDataUpdated -->')
  //     // * Prop drill potential instance callback...
  //     props.onRowDataUpdated && props.onRowDataUpdated(event)

  //     updateRowCount(event)
  //   },
  //   [props],
  // )

  // FilterChangedEvent<TData>
  const onFilterChanged = React.useCallback(
    (event: FilterChangedEvent) => {
      // console.debug('\nonFilterChanged -->')
      // * Prop drill potential instance callback...
      props.onFilterChanged && props.onFilterChanged(event) // REVIEW >> This is weird

      // // updateRowCount(event)
      // const pageSize = event.api.paginationGetPageSize()
      // const rowCount = event.api.getDisplayedRowCount()
      // // REVIEW >> Values always stale here...
      // console.debug(`\t>> updateRowCount() --> rowCount: ${rowCount} pageSize: ${pageSize}`)

      // console.debug(JSON.stringify(event.api.getFilterModel(), null, 2))
      // console.debug('\n')
    },
    [props],
  )

  // FilterModifiedEvent<TData>
  const onFilterModified = React.useCallback(
    (event: FilterModifiedEvent) => {
      // console.debug('\nonFilterModified -->')
      // * Prop drill potential instance callback...
      props.onFilterModified && props.onFilterModified(event)

      // // updateRowCount(event)
      // const pageSize = event.api.paginationGetPageSize()
      // const rowCount = event.api.getDisplayedRowCount()
      // console.debug(`\t>> updateRowCount() --> rowCount: ${rowCount} pageSize: ${pageSize}`)
    },
    [props],
  )

  const onModelUpdated = React.useCallback(
    (event: ModelUpdatedEvent) => {
      // console.debug('\nonModelUpdated -->')
      // console.debug(event)
      // * Prop drill potential instance callback...
      props.onModelUpdated && props.onModelUpdated(event)
    },
    [props],
  )

  // ColumnResizedEvent<TData>
  const onColumnResized = React.useCallback(
    (event: ColumnResizedEvent) => {
      // * Prop drill potential instance callback...
      props.onColumnResized && props.onColumnResized(event)
      // console.debug('\n>> onColumnResized', event, '\n')
      setGridWidth(getTotalColumnsWidth(event.api))
    },
    [props],
  )

  const defaultColDef: ColDef = React.useMemo(
    () => ({
      sortable: true, // REVIEW >> Why is this not having an effect?
      resizable: true,
      // filter: 'agSetColumnFilter',
      editable: false,
      maxWidth: 325,
      // cellDataType: 'text',
      // * Filter tab contents and order determined by `menuTabs` array order.   The valid values are: 'filterMenuTab', 'generalMenuTab' and 'columnsMenuTab'.
      menuTabs: [
        'filterMenuTab',
        // 'generalMenuTab',
        'columnsMenuTab',
      ],
      ...props.defaultColDef,
    }),
    [props.defaultColDef],
  )

  const gridOptions: GridOptions = React.useMemo(
    () => ({
      rowSelection: 'multiple' as const,
      sideBar: {
        hiddenByDefault: true,
      },
      paginationPageSize: GridDefaults.paginationPageSize, // REVIEW >> share this value with Pagination component
      // paginateChildRows: true,
      // enableAdvancedFilter: true,
      // popupParent: document.getElementById('wrapper'),
      // initialState: {
      //   filter: {
      //     advancedFilterModel: initialAdvancedFilterModel,
      //   },
      // },
      getContextMenuItems: (params) => [], // note >> Disables context menu
      overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">No row data to show</span>',
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
      // loadingOverlayComponent: { loadingOverlayComponent }, // * would use memoized component
      // loadingOverlayComponentParams: { loadingOverlayComponentParams },
      // noRowsOverlayComponent: { noRowsOverlayComponent },
      // noRowsOverlayComponentParams: { noRowsOverlayComponentParams },
      // reactiveCustomComponents: true,
      ...props, // * columnDefs // * (ColDef<TData> | ColGroupDef<TData>)[] | null;

      // * Following cannot be overwritten on instantiation...
      suppressHorizontalScroll: true, // Note >> horizontal scroll fixing
      scrollbarWidth: 0, // Note >> vertical scroll fixing
      pagination: true,
      // paginationAutoPageSize: false,
      // suppressPaginationPanel: true, // * If we want to provide our own pagination controls (decided against because it very problematic)
      suppressPaginationPanel,
      // serverSideStoreType: 'partial',
      // cacheBlockSize: 20,
      // maxBlocksInCache: 3,
      animateRows: true,

      defaultColDef, // * ColDef<TData> A default column definition. Items defined in the actual column definitions get precedence.
      // getRowStyle: (params) => state.rowStyle,
      onGridReady, // ? The grid has initialised and is ready for most api calls, but may not be fully rendered yet
      // onGridPreDestroyed, // ? Invoked immediately before the grid is destroyed. This is useful for cleanup logic that needs to run before the grid is torn down.
      onFirstDataRendered, // ? Fired the first time data is rendered into the grid. Use this event if you want to auto resize columns based on their contents */
      onFilterChanged,
      onColumnResized,
      // onGridSizeChanged, // ? The size of the grid `div` has changed. In other words, the grid was resized.
      // onFilterOpened, // ? Filter has been modified and applied.
      onFilterModified, // ? Filter was modified but not applied. Used when filters have 'Apply' buttons.
      // onAdvancedFilterBuilderVisibleChanged, // ? Advanced Filter Builder visibility has changed (opened or closed).
      // onGroupExpandedOrCollapsed,
      onModelUpdated, // ? Displayed rows have changed. Triggered after sort, filter or tree expand / collapse events.
      // onViewportChanged, // ? Which rows are rendered in the DOM has changed.
      // onStateUpdated, // ? Grid state has been updated.
      // onPaginationChanged, // ? Triggered every time the paging state changes. Some of the most common scenarios for this event to be triggered are: The page size changes; The current shown page is changed; New data is loaded onto the grid.
      // onRowDataUpdated, // ? Client-Side Row Model only. The client has updated data for the grid by either a) setting new Row Data or b) Applying a Row Transaction.
      // onAsyncTransactionsFlushed, // ? Async transactions have been applied. Contains a list of all transaction results.
      // onStoreRefreshed, // ? A server side store has finished refreshing.
      // onSortChanged, // ? Sort has changed. The grid also listens for this and updates the model.
    }),
    [
      defaultColDef,
      onColumnResized,
      onFilterChanged,
      onFilterModified,
      onFirstDataRendered,
      onGridReady,
      onModelUpdated,
      props,
      suppressPaginationPanel,
    ],
  )
  // console.debug(gridOptions)

  const changeHandler = (value: string) => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setGridOption('quickFilterText', value)
    }
  }

  // REVIEW >> Was it intentional to leave deps array empty?
  const debouncedChangeHandler = React.useMemo(() => debounce((value: string) => changeHandler(value), 400), [])

  // REVIEW >> These need to be outside the main function
  function QuickSearch() {
    return (
      <input
        type="text"
        className="rounded-md border border-solid border-gray-darker-50 bg-white px-sm py-xs text-2xl text-gray-darker-800 font-regular"
        placeholder="Quick search"
        onChange={(e) => {
          debouncedChangeHandler(e.target.value)
        }}
      />
    )
  }

  // REVIEW >> These need to be outside the main function
  function ExportAsCSV() {
    return (
      <ButtonV2 variant="secondary" size="md" onClick={onExportButton}>
        Export CSV
      </ButtonV2>
    )
  }

  function renderHeader() {
    return customHeader ? (
      customHeader({
        quickSearch: <QuickSearch />,
        exportAsCSV: <ExportAsCSV />,
        // cellSizeRadios: <CellSizeRadios />
      })
    ) : (
      // * Default header...
      <>
        <QuickSearch />
        <ExportAsCSV />
        {/* <CellSizeRadios /> */}
      </>
    )
  }

  const id = `agg-wrapper-${React.useId().replace(/:/g, '')}`

  return (
    <AGGridContext>
      <div
        id={id}
        ref={observe}
        className={cn(
          'grid h-full grid-rows-[auto_auto_auto] rounded-lg border-off-white shadow',
          { [`[@media(min-width:${alignStretchMinWidth}px)]:grid-rows-[auto_1fr_auto]`]: alignStretch },
          { 'content-start': !alignStretch },
          className,
        )}
      >
        <div className="flex gap-2xl rounded-tl-lg rounded-tr-lg bg-white p-lg">{renderHeader()}</div>

        <div
          className={cn('ag-theme-alpine ag-theme-fsg grid h-full grid-rows-[1fr] overflow-hidden', {
            [`min-h-[${minGridHeight}px]`]: alignStretch,
          })}
        >
          <AgGridReact ref={gridRef} {...gridOptions} domLayout={alignStretch ? 'normal' : 'autoHeight'} />
        </div>
        {suppressPaginationPanel ? (
          <div className="min-h-min rounded-bl-xl rounded-br-xl border border-t-0 border-solid border-gray-darker-200 bg-white p-lg"></div>
        ) : null}
      </div>
    </AGGridContext>
  )
}