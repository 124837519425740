import React from 'react'
import { signOut } from 'next-auth/react'

import { ButtonV2, Popover, PopoverContent, PopoverTrigger } from '@fsg/gui-bits'
import { cn } from '@fsg/utils'

interface Props {
  className?: string
  name: string
}

export function NameBubble({ className, name }: Props) {
  return (
    <Popover>
      <PopoverTrigger className="grow-0">
        <div className={cn(className, 'flex h-[28px] w-[28px] items-center justify-center rounded-full bg-gray')}>
          <span className="p-sm text-lg font-semibold text-gray-darker-500">{name.charAt(0).toUpperCase()}</span>
        </div>
      </PopoverTrigger>
      <PopoverContent className="mx-5 my-3 grid justify-items-center text-lg font-semibold">
        <h2 className="text-gray-dark">{name}</h2>
        <ButtonV2 className="mt-3 text-xl font-semibold text-white" onClick={() => signOut()}>
          <span>Logout</span>
        </ButtonV2>
      </PopoverContent>
    </Popover>
  )
}
