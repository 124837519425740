'use client'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import classNames from 'classnames/bind'

import { GridDefaults } from '@fsg/gui-bits'
import { ArrowLeft, ArrowRight } from '@fsg/icons'

import { Button, ButtonGroup } from '../'
import moduleStyles from './Pagination.module.scss'

// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!
// ! DEPRECATED !!!!!!!!!!!!!!

const cx = classNames.bind(moduleStyles)

type CustomPaginationProps = {
  api: AgGridReact['api']
}

export function Pagination({ api }: CustomPaginationProps) {
  const [currentPage, setCurrentPage] = useState(api.paginationGetCurrentPage())
  const [totalPages, setTotalPages] = useState(api.paginationGetTotalPages())

  useEffect(() => {
    function updatePagination() {
      setCurrentPage(api.paginationGetCurrentPage())
      setTotalPages(api.paginationGetTotalPages())
    }
    updatePagination()

    // Since changes in values on the grid ref will not trigger a state update
    // listen for this event every time the pagination changes and update state
    // https://www.ag-grid.com/react-data-grid/row-pagination/#pagination-events
    api.addEventListener('paginationChanged', updatePagination)

    return () => {
      api.removeEventListener('paginationChanged', updatePagination)
    }
  }, [api])

  const goToPage = useCallback((pageNumber: number) => api.paginationGoToPage(pageNumber), [api]) // subtract 1 because ag-grid pages are 0-indexed

  function handlePageSizeChange(size: string) {
    api.paginationSetPageSize(Number(size))
  }

  const variants = {
    span: 'text-xl',
    divider: 'bg-gray-dark',
    select: 'border border-solid border-gray rounded-md font-regular text-xl px-md py-sm',
    // select: 'ring-solid ring-1 ring-gray ring-offset-4 rounded-lg font-regular text-xl px-md py-sm m-2',
  }

  const pageSelectOptions = useMemo(() => {
    const pages = api.paginationGetTotalPages()
    const arr = Array.from({ length: pages }, (v, index) => index) // creates an array of numbers from 0 to `pages`
    return arr.map((opt) => (
      <option key={opt} value={opt}>
        {opt + 1}
      </option>
    ))
  }, [api])

  const onSelectPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    const page = Number(value)
    goToPage(page)
  }

  // if (totalPages <= 1) {
  //   return <div className="min-h-min rounded-bl-xl rounded-br-xl border border-t-0 border-solid border-gray-darker-200 bg-white p-lg"></div>
  // }

  // slight change to select, setting defaultValue over 'selected' on the option to clear warning.
  return (
    <div className="min-h-min rounded-bl-xl rounded-br-xl border border-t-0 border-solid border-gray-darker-200 bg-white p-lg">
      {totalPages > 1 ? (
        <div className={cx('m--wrapper')}>
          <div className={cx('m--controls')}>
            <select
              defaultValue={GridDefaults.paginationPageSize}
              value={currentPage}
              className={variants.select}
              onChange={(e) => handlePageSizeChange(e.target.value)}
            >
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
              <option value={50}>50 per page</option>
              <option value={100}>100 per page</option>
            </select>
            <div className={cx('m--divider', variants.divider)}></div>
            <span className={variants.span}>Page</span>
            <select className={variants.select} onChange={onSelectPage} value={currentPage}>
              {pageSelectOptions}
            </select>
            <span className={variants.span}>of {totalPages}</span>
          </div>
          <div>
            <ButtonGroup>
              <Button withIcon size="small" buttonType="secondary" onClick={() => api.paginationGoToPreviousPage()}>
                <ArrowLeft className="p-xs" />
              </Button>
              <Button withIcon size="small" buttonType="secondary" onClick={() => api.paginationGoToNextPage()}>
                <ArrowRight className="p-xs" />
              </Button>
            </ButtonGroup>
          </div>
        </div>
      ) : null}
    </div>
  )
}
